import { Message, Conversation, GetConversationsApiResponse, GetMessagesApiResponse, CreateConversationApiResponse} from './models';
import { StreamSend, StreamingAdapterObserver } from '@nlux/react';

export const getCredentials = (): {apiToken: string, apiURL: string} => {

  return {
    apiToken: localStorage.getItem('apiToken') ?? '',
    apiURL: localStorage.getItem('apiURL') ?? ''
  }
}

export const fetchConversations = async (): Promise<Conversation[]> => {

  const {apiToken, apiURL} = getCredentials();
  const response = await fetch(`${apiURL}/api/v1/conversations`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    }
  });

  const apiResponse: GetConversationsApiResponse = await response.json();
  return apiResponse.data;
};

export const fetchMessages = async (conversationId: string): Promise<Message[]> => {

  const {apiToken, apiURL} = getCredentials();
  const response = await fetch(`${apiURL}/api/v1/conversations/${conversationId}/messages`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    }
  });

  const apiResponse: GetMessagesApiResponse = await response.json();
  return apiResponse.data.reverse();
}

export const createConversation = async (conversation: {title: string, type: string}): Promise<Conversation> => {

  const {apiToken, apiURL} = getCredentials();
  const response = await fetch(`${apiURL}/api/v1/conversations`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    },
    body: JSON.stringify({
      title: conversation.title,
      framework: {
        type: conversation.type
      }
    })
  });

  const apiResponse: CreateConversationApiResponse = await response.json();
  return apiResponse.data;
}

/* AI request */
export const sendMessage: StreamSend = async (
  prompt: string,
  observer: StreamingAdapterObserver,
) => {

  const conversationId = sessionStorage.getItem("conversationId"); // FIXME!!!
  const body = {
    content: [
      {
        type: "text",
        text: {
          value: prompt
        }
      }
    ]
  };

  const {apiToken, apiURL} = getCredentials();
  const response = await fetch(`${apiURL}/api/v1/conversations/${conversationId}/messages`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${apiToken}`},
    body: JSON.stringify(body),
  });

  if (response.status !== 201) {
    observer.error(new Error('Failed to connect to the server'));
    return;
  }

  if (!response.body) {
    return;
  }

  const responseStream = await fetch(`${apiURL}/api/v1/conversations/${conversationId}/messages/stream`, {
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${apiToken}`},
  });

  if (responseStream.status !== 200) {
    observer.error(new Error('Failed to connect to the server'));
    return;
  }

  if (!responseStream.body) {
    return;
  }

  const reader = responseStream.body.getReader();
  const textDecoder = new TextDecoder();

  let doneStream = false;
  while (!doneStream) {
    const {value, done} = await reader.read();
    if (done) {
      doneStream = true;
    } else {
      const content = textDecoder.decode(value);
      if (content) {
        observer.next(content);
      }
    }
  }

  observer.complete();
};
