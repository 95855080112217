import React, { useEffect } from 'react';
import { AiChat, ChatAdapter, ChatItem, PersonaOptions } from '@nlux/react';
import { useParams } from 'react-router-dom';
import { fetchMessages } from '../api/requests';
import { useQuery } from '@tanstack/react-query';
import { Message } from '../api/models';

export interface ConversationProps {
  adapter: ChatAdapter<string>;
  personas: PersonaOptions;
  displayOptions: any;
}

const Conversation: React.FC<ConversationProps> = ({ adapter, personas, displayOptions}) => {

  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ['messages', id],
    queryFn: () => {
      if (!id) {
        return;
      }
      return fetchMessages(id);
    },
    select: (data): ChatItem[] => {
      if (!data) {
        return [];
      }

      return data?.map((message: Message): ChatItem => {
        return {
         role: message.role,
         message: message.content[0].text.value
        }
      });
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    sessionStorage.setItem("conversationId", id || "");
  }, [id]);

  return (
    <AiChat
      adapter={ adapter }
      initialConversation={data}
      // messageOptions={{ syntaxHighlighter: highlighter }}
      personaOptions={ personas }
      displayOptions={ displayOptions }
    />
  );
};

export default Conversation;
