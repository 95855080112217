import {
    Button,
    FormControl,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack
} from "@chakra-ui/react"
import React, {useState} from "react"

export interface NewConversationProps {
    inputRef: React.MutableRefObject<null>;
    isOpen: boolean;
    onClose: () => void;
    onStart: (title: string, type: string) => void;
}

const NewConversation: React.FC<NewConversationProps> = ({inputRef, onClose, onStart, isOpen}) => {

    const [titleValue, setTitleValue] = useState('');
    const [typeValue, setTypeValue] = useState('mad');
    const [isInvalid, setIsInvalid] = useState(false);

    return (
        <Modal
            initialFocusRef={inputRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>New conversation</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <Stack spacing={3}>
                        <FormControl>
                            <Input ref={inputRef} placeholder='Title' onChange={(e) => setTitleValue(e.target.value)}
                                   errorBorderColor='crimson' isInvalid={isInvalid}/>
                        </FormControl>
                        <FormControl>
                            <Select placeholder='Select type' onChange={(e) => setTypeValue(e.target.value)} defaultValue='mad'>
                                <option value='mad'>MAD</option>
                                <option value='cmd'>CMD</option>
                            </Select>
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={() => {
                        if (titleValue === '' || typeValue === '') {
                            setIsInvalid(true);
                            return;
                        }

                        onStart(titleValue, typeValue);
                        onClose();
                    }}>
                        Start
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NewConversation;
