/* eslint-disable import/no-anonymous-default-export */
import { useAsStreamAdapter } from '@nlux/react';
import '@nlux/themes/nova.css';
import '@nlux/highlighter/dark-theme.css';
import { ChakraProvider, Box, Flex, HStack, IconButton, VStack, Button } from '@chakra-ui/react'

import { personas } from './api/personas';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from './screens/Home';
import Conversation from './screens/Conversation';
import { getCredentials, sendMessage } from './api/requests';
import { theme } from './styles/ui';
import ChatsList from './components/ChatsList';
import NewChat from './components/NewChat';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useState } from 'react';

export default () => {

    const adapter = useAsStreamAdapter(sendMessage, []);
    const queryClient = new QueryClient()
    const {apiToken} = getCredentials();
    const [showSidebar, setShowSidebar] = useState(apiToken !== '');

    return (
        <BrowserRouter>
        <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>

        <Flex height="100vh" direction="row">
          {showSidebar && <Box
            width="260px"
            bg="blackAlpha.50"
            color="blackAlpha.900"
            display="flex"
            flexDirection="column"
          >
            <Box p={4}>
              <HStack justifyContent="space-between">
                <IconButton
                icon={<ArrowLeftIcon/>}
                aria-label="Hide sidebar"
                onClick={() => setShowSidebar(false)}
                />
                <NewChat/>
              </HStack>
            </Box>

            <Box flex="1" p={2} overflowY="scroll">
              <VStack align="stretch">
                <ChatsList/>
              </VStack>
            </Box>

            <Box p={4}>
              <Button width="100%" variant="outline">
                Coming soon
              </Button>
            </Box>
          </Box>}

          <Flex direction="column" flex="1" bg="white">
            <Box p={4} boxShadow="md">
              {apiToken !== '' && showSidebar === false && <IconButton
                icon={<ArrowRightIcon/>}
                aria-label="Show sidebar"
                onClick={() => setShowSidebar(true)}
                />}
            </Box>

            <Box as="main" flex="1" bg="white" p={2} overflowY="scroll">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/conversation/:id" element={<Conversation adapter={ adapter } personas={ personas } displayOptions={{ themeId: 'nova', colorScheme: 'light' }} />} />
              </Routes>
            </Box>

          </Flex>
      </Flex>

        </ChakraProvider>
        </QueryClientProvider>
        </BrowserRouter>
    );
};