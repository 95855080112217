import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchConversations } from '../api/requests';

import {
    List,
    ListItem,
} from '@chakra-ui/react'
import {
    useMatch,
    useNavigate,
} from "react-router-dom";

const ChatsList: React.FC = () => {

  const match = useMatch('/conversation/:id');
  const navigate = useNavigate();
  const id = match?.params.id ?? '';

  const { isPending, error, data } = useQuery({
    queryKey: ['conversations'],
    queryFn: () => {
      return fetchConversations()
    }
  });

  return (
    <>
    {isPending &&
      <span>Loading...</span>
    }

    {error &&
      <span>Error: {error.message}</span>
    }

    <List size="xl" variant={"menu"}>
    {data && data.length > 0 && data.map((conversation) => (
      <ListItem key={`chat-${conversation.id}`} bg={id === conversation.id ? 'blackAlpha.100' : 'none'} onClick={() => {
        navigate(`/conversation/${conversation.id}`);
      }}>
        {conversation.title} ({conversation.framework.type})
      </ListItem>
    ))}
    </List>
    </>
  );
};

export default ChatsList;
