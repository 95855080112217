import { Box, Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { getCredentials } from '../api/requests';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {

  const navigate = useNavigate();

  const inputRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {apiToken, apiURL} = getCredentials();
  const [apiTokenValue, setApiTokenValue] = useState(apiToken);
  const [apiURLValue, setApiURLValue] = useState(apiURL);
  const [isInvalidKey, setIsInvalidKey] = useState(false);
  const [isInvalidURL, setIsInvalidURL] = useState(false);

  return (
    <Box display="grid" placeItems="center" height="100%">
    <Button onClick={onOpen}>Settings</Button>
      <Modal
        initialFocusRef={inputRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Input ref={inputRef} placeholder='API URL' onChange={(e) => setApiURLValue(e.target.value)} errorBorderColor='crimson' isInvalid={isInvalidURL} value={apiURLValue}/>
            </FormControl>

            <FormControl py="3">
              <Input placeholder='API Key' onChange={(e) => setApiTokenValue(e.target.value)} errorBorderColor='crimson' isInvalid={isInvalidKey} value={apiTokenValue} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {

                setIsInvalidKey(apiTokenValue === '');
                setIsInvalidURL(apiURLValue === '');

                if (apiTokenValue === '' || apiURLValue === '') {
                  return;
                }

                localStorage.setItem('apiToken', apiTokenValue);
                localStorage.setItem('apiURL', apiURLValue)

                navigate(0);
                onClose();
            }}>
              Set
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
};

export default Home;
