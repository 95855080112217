import {
  defineStyle,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/styled-system";

import { listAnatomy as parts } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);


const menu = definePartsStyle((props) => ({
  container: {
    shadow: "none",
    border: "none",
    borderStyle: "none",
  },
  item: {
    display: "flex",
    alignItems: "center",
    // bg: mode("blackAlpha.400", "blackAlpha.400")(props),
    borderRadius: "5px",
    cursor: "pointer",
    w: "full",
    p: 2,
    m: 0,
    shadow: "none",
  }
}));

export const listTheme = defineMultiStyleConfig({
  variants: {
    menu,
  }
});

export const theme = extendTheme({
  components: { List: listTheme }
});