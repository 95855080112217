import React, { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createConversation } from '../api/requests';

import {
    IconButton,
    useDisclosure,
} from '@chakra-ui/react'
import NewConversation from './NewConversation';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';

const NewChat: React.FC = () => {

  const queryClient = useQueryClient();
  const inputRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (conversation: {title: string, type: string}) => {
      return createConversation(conversation);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['conversations'] });
      navigate(`/conversation/${data.id}`);
    },
  })

  const onStart = (title: string, type: string) => {
      mutation.mutate({title, type});
  };

  return (
    <>
    <IconButton
      icon={<EditIcon />}
      aria-label="New conversation"
      // variant="ghost"
      // colorScheme="blue"
      onClick={onOpen}/>
    <NewConversation inputRef={inputRef} onClose={onClose} isOpen={isOpen} onStart={onStart} />
    </>
  );
};

export default NewChat;
